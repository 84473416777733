<template>
  <div>
    <notification-popup
        :message="'Услуга добавлена в корзину'"
        :show="showNotification"
    />
    <div class="Catalog">
      <h1>Каталог</h1>
      <div class="categories-container" @click="openModal">
        <div class="categories-list">
          <template v-for="(category, index) in displayCategories" :key="index">
            <p class="category-item">
              <span
                  :style="{ color: getCategoryColor(index) }"
                  class="category-name">
                {{ category }}
              </span>
              <span class="category-count">{{ getCategoryCount(index) }}</span>
            </p>
          </template>
        </div>
        <img
            class="expand-arrow"
            :class="{ 'expanded': isCategoriesExpanded }"
            src="/static/img/Frame.svg">
      </div>

      <!-- Modal Structure -->
      <div v-if="showModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
          <AppCategories @close="closeModal"/>
        </div>
      </div>

      <div class="sort-control">
        <div class="sort-button" @click="toggleSort">
          <input
              background="/static/img/Vector.svg"
              class="filter"
              readonly
              :value="sortActive
                ? `По цене (${sortDirection === 'asc' ? '↑' : '↓'})`
                : 'По цене'">
        </div>
      </div>

      <div v-if="displayedProducts.length === 0" class="empty-promotion">
        <p>{{ searchTerm ? 'Ничего не найдено' : 'Нет акций' }}</p>
      </div>
      <div v-else class="list-promotions">
        <div
            class="item-promotion"
            v-for="product in displayedProducts"
            :key="product.id"
            @click="navigateToItem(product.id)">
          <div class="top-div__item-catalog">
            <img :src="product.image" alt="Product Image">
          </div>
          <div class="bottom-div__item-promotion">
            <p>{{ product.name }}</p>
            <div class="old-price-and-new">
              <p class="price">{{ product.price }} ₽</p>
              <button
                class="add-to-cart-btn"
                :class="{ 'in-cart': isInCart(product) }"
                @click.stop="toggleCart(product)"
              >
                <svg
                  v-if="isInCart(product)"
                  class="cart-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
                <svg
                  v-else
                  class="cart-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NotificationPopup from './NotificationPopup.vue'
import AppCategories from './AppCategories.vue'

export default {
  name: 'AppCatalog',
  components: {
    NotificationPopup,
    AppCategories
  },
  props: {
    category: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isCategoriesExpanded: false,
      sortDirection: 'asc',
      sortActive: false,
      showNotification: false,
      showModal: false,
      searchQuery: '',
      debounceTimeout: null
    }
  },
  created() {
    // Initialize search query from URL
    this.searchQuery = this.$route.query.search || '';
    this.$store.dispatch('setSearchTerm', this.searchQuery);

    if (this.category !== null) {
      this.selectCategory(parseInt(this.category));
      // Clear search when category is selected
      this.searchQuery = '';
      this.$store.dispatch('setSearchTerm', '');
    }

    if (this.$route.params.showCategoriesPopup) {
      this.openModal();
    }
  },
  watch: {
    '$route.query.search'(newVal) {
      // Update searchQuery when route changes
      this.searchQuery = newVal || '';
      this.$store.dispatch('setSearchTerm', newVal || '');
    },
    '$route.params.showCategoriesPopup': {
      handler(newVal) {
        if (newVal) {
          this.openModal();
        }
      },
      immediate: true
    },
    category(newValue) {
      if (newValue !== null) {
        this.selectCategory(parseInt(newValue));
        // Clear search when category changes
        this.searchQuery = '';
        this.$store.dispatch('setSearchTerm', '');
        this.updateRoute();
      }
    },
    showNotification(newVal) {
      console.log('Parent showNotification changed:', newVal)
    }
  },
  computed: {
    ...mapState(['cart', 'categories', 'selectedCategory', 'searchTerm']),
    ...mapGetters(['filteredProducts']),
 displayedProducts() {
        // If there's a search term, search across all products excluding category 999
        if (this.searchTerm) {
            let products = this.$store.state.products.filter(product =>
                product.name.toLowerCase().includes(this.searchTerm.toLowerCase()) &&
                product.category_id !== 999  // Changed from category to category_id
            );

            // Apply sorting if active
            if (this.sortActive) {
                products = [...products].sort((a, b) => {
                    return this.sortDirection === 'asc'
                        ? a.price - b.price
                        : b.price - a.price;
                });
            }

            return products;
        }

        // If no search term, use filtered products by category
        let products = this.filteredProducts;

        // Apply sorting if active
        if (this.sortActive) {
            products = [...products].sort((a, b) => {
                return this.sortDirection === 'asc'
                    ? a.price - b.price
                    : b.price - a.price;
            });
        }

        return products;
    },
    displayCategories() {
      if (this.selectedCategory === null) {
        return this.categories.slice(0, this.isCategoriesExpanded ? this.categories.length : 1);
      }

      const selectedCategory = this.categories[this.selectedCategory];
      const otherCategories = this.categories.filter((_, index) => index !== this.selectedCategory);
      return [selectedCategory, ...otherCategories].slice(0, this.isCategoriesExpanded ? this.categories.length : 1);
    }
  },
  methods: {
    ...mapActions(['addToCart', 'removeFromCart', 'selectCategory']),

    handleSearch() {
      // Clear debounce timeout if it exists
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      // Debounce search input
      this.debounceTimeout = setTimeout(() => {
        const trimmedSearch = this.searchQuery.trim();

        // Clear category selection when searching
        if (trimmedSearch) {
          this.selectCategory(null);
        }

        // Update store and route
        this.$store.dispatch('setSearchTerm', trimmedSearch);
        this.updateRoute();
      }, 300);
    },

    updateRoute() {
      const query = { ...this.$route.query };

      if (this.searchQuery.trim()) {
        query.search = this.searchQuery.trim();
      } else {
        delete query.search;
      }

      // Use router.replace to avoid adding to history stack
      this.$router.replace({
        name: 'catalog',
        query,
        params: this.category !== null ? { category: this.category.toString() } : {}
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },

    handleCategoryClick(index) {
      // Clear search when selecting category
      this.searchQuery = '';
      this.$store.dispatch('setSearchTerm', '');

      this.selectCategory(index);
      this.$router.push({
        name: 'catalog',
        params: { category: index.toString() },
        query: {} // Clear search query from URL
      });
    },

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

     getCategoryCount(categoryIndex) {
        // Update to use category_id instead of category
        return this.$store.state.products.filter(p => p.category_id === categoryIndex).length;
    },

    getCategoryColor(index) {
      return this.selectedCategory === index ? '#43D5CA' : 'inherit';
    },

    isInCart(product) {
      return this.cart.some(item => item.id === product.id);
    },

    navigateToItem(id) {
      this.$router.push({ name: 'Item', params: { id }});
    },

    toggleCart(product) {
      event.preventDefault();
      event.stopPropagation();

      if (this.isInCart(product)) {
        this.removeFromCart(product);
      } else {
        this.addToCart(product);
        this.showNotification = true;

        setTimeout(() => {
          this.showNotification = false;
        }, 3000);
      }
    },

    toggleSort() {
      if (!this.sortActive) {
        this.sortActive = true;
        this.sortDirection = 'asc';
      } else {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
    }
  }
}
</script>

<style scoped>
.Catalog {
  padding: 20px;
  padding-bottom: 100px;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 100vw;
  width: 100%;
}

/* Rest of your existing styles */
h1 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.categories-container {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 16px;
  cursor: pointer;
}

.categories-list {
  flex: 1;
}

.category-item {
  margin: 5px 0;
}

.category-name {
  margin-right: 6px;
}

.category-count {
  color: #707579;
}

.expand-arrow {
  margin-left: auto;
  margin-right: 12px;
  transition: transform 0.3s ease;
}

.expand-arrow.expanded {
  transform: rotate(180deg);
}

.sort-control {
  display: flex;
  margin-bottom: 30px;
}

.sort-button {
  position: relative;
  cursor: pointer;
}

.filter {
  cursor: pointer;
}

.sort-icon {
  transform: translate(-200%, 0);
}

.bottom-div__item-promotion > p {
  line-height: 1.0;
  font-size: 15px;
  height: 45px;
}

.bottom-div__item-promotion {
  padding: 10px;
}

.price {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.old-price-and-new > button {
  margin: 0;
  margin-left: auto;
}

.item-promotion {
  margin: 10px 0;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.item-promotion:hover {
  transform: scale(1.02);
}

.old-price-and-new {
  margin-top: 12px;
  display: flex;
  justify-content: top;
  align-items: center;
}

.add-to-cart-btn.in-cart {
  background-color: #707579;
}
.cart-icon {
  width: 24px;
  height: 24px;
}

.add-to-cart-btn{
  padding: 0;
}
</style>
