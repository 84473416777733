<template>
  <div id="app">
    <AppHeader/>
    <div v-if="isLoading" class="loading">Загрузка...</div>
    <div v-if="error" class="error">{{ error }}</div>
    <router-view v-else/>
    <AppMenu/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from './components/AppHeader.vue'
import AppMenu from './components/AppMenu.vue'

export default {
  created() {
    this.$store.dispatch('auth/initializeAuth');
    this.$store.dispatch('fetchProducts');
    this.$store.dispatch('fetchCategories');
  },
  name: 'App',
  components: {
    AppHeader,
    AppMenu
  },
  computed: {
    ...mapGetters(['isLoading', 'error'])
  }
}
</script>

<style>
@import '../public/static/css/style.css';
@import '../public/static/css/reset.css';

.loading {
  text-align: center;
  padding: 20px;
}

.error {
  color: red;
  text-align: center;
  padding: 20px;
}
</style>