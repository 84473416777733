import {createStore} from 'vuex';
import auth from './auth';

export default createStore({
    modules: {
        auth
    },
    state: {
        selectedCategory: 0,
        products: [],
        isLoading: false,
        error: null,
        cart: [],
        categories: [],
        user: {
            name: '',
            phone: '',
            address: '',
            birthdate: ''
        },
        searchTerm: '',
        bonusPercentage: 5 // 5% bonus on all purchases
    },

    mutations: {

        SET_PRODUCTS(state, products) {
            state.products = products;
        },
        SET_LOADING(state, status) {
            state.isLoading = status;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },

        SET_CATEGORIES(state, categories) {
            state.categories = categories;
        },

        ADD_TO_CART(state, item) {
            const existingItem = state.cart.find(i => i.id === item.id);
            if (!existingItem) {
                state.cart.push({...item, quantity: 1});
            }
        },

        REMOVE_FROM_CART(state, item) {
            const index = state.cart.findIndex(i => i.id === item.id);
            if (index !== -1) {
                state.cart.splice(index, 1);
            }
        },

        UPDATE_USER(state, userData) {
            state.user = {...state.user, ...userData};
        },

        SET_SELECTED_CATEGORY(state, categoryIndex) {
            state.selectedCategory = categoryIndex;
        },
        SET_SEARCH_TERM(state, term) {
            state.searchTerm = term;
        },
    },

    actions: {

        async fetchProducts({commit}) {
            try {
                console.log('Starting to fetch products');
                commit('SET_LOADING', true);
                commit('SET_ERROR', null);

                const response = await fetch('https://dedovagency.ru/backend/api/products');
                console.log('API Response status:', response.status);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const products = await response.json();
                console.log('Received products:', products);

                if (Array.isArray(products)) {
                    commit('SET_PRODUCTS', products);
                } else if (products.data && Array.isArray(products.data)) {
                    commit('SET_PRODUCTS', products.data);
                } else {
                    console.error('Unexpected products format:', products);
                    throw new Error('Invalid products data format');
                }
            } catch (error) {
                console.error('Error fetching products:', error);
                commit('SET_ERROR', error.message);
            } finally {
                commit('SET_LOADING', false);
            }
        },


        async fetchCategories({commit}) {
            try {
                console.log('Starting to fetch categories');
                commit('SET_LOADING', true);
                commit('SET_ERROR', null);

                const response = await fetch('https://dedovagency.ru/backend/api/categories_list');
                console.log('Categories API Response status:', response.status);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const categories = await response.json();
                console.log('Received categories:', categories);

                if (Array.isArray(categories)) {
                    commit('SET_CATEGORIES', categories);
                } else if (categories.data && Array.isArray(categories.data)) {
                    commit('SET_CATEGORIES', categories.data);
                } else {
                    console.error('Unexpected categories format:', categories);
                    throw new Error('Invalid categories data format');
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
                commit('SET_ERROR', error.message);
            } finally {
                commit('SET_LOADING', false);
            }
        },


        addToCart({commit}, item) {
            commit('ADD_TO_CART', item);
            console.log("item added to cart");
        },

        removeFromCart({commit}, item) {
            commit('REMOVE_FROM_CART', item);
        },
        updateUser({commit}, userData) {
            commit('UPDATE_USER', userData);
        },
        selectCategory({commit}, categoryIndex) {
            commit('SET_SELECTED_CATEGORY', categoryIndex);
        },
        setSearchTerm({commit}, term) {
            commit('SET_SEARCH_TERM', term);
        },
        async fetchUser({commit}, telegramData) {
            try {
                const response = await fetch('https://dedovagency.ru/backend/api/user/auth', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({telegramData})
                });

                if (response.ok) {
                    const userData = await response.json();
                    commit('UPDATE_USER', userData);
                    return userData;
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        }
    },

    getters: {
        isLoading: state => state.isLoading,
        error: state => state.error,
        products: state => state.products,
        cart: state => state.cart,
        user: state => state.user,

        getProductFaqs: (state) => (productId) => {
            const product = state.products.find(p => p.id === productId);
            return product?.faq || [];
        },

        filteredProducts(state) {
            console.log('Filtering products:', state.products);
            console.log('Selected category:', state.selectedCategory);

            let products = state.products;

            if (!Array.isArray(products)) {
                console.error('Products is not an array:', products);
                return [];
            }

            // Changed from product.category to product.category_id
            products = products.filter(product => product.category_id === state.selectedCategory + 1);
            console.log('Products after category filter:', products);

            if (state.searchTerm) {
                products = products.filter(product =>
                    product.name?.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
                    product.description?.toLowerCase().includes(state.searchTerm.toLowerCase())
                );
                console.log('Products after search filter:', products);
            }

            return products;
        },


        filteredPromotions(state) {
            let products = state.products;

            products = products.filter(product => product.category === 999);


            return products;
        },

        getDiscountedPrice: () => (item) => {
            if (!item.discount) return item.price;

            if (item.discount.type === 'percentage') {
                return Math.round(item.price * (1 - item.discount.value / 100));
            } else if (item.discount.type === 'fixed') {
                return Math.max(0, item.price - item.discount.value);
            }
            return item.price;
        },

        cartItemsWithDiscounts: (state, getters) => {
            return state.cart.map(item => ({
                ...item,
                originalPrice: item.price,
                discountedPrice: getters.getDiscountedPrice(item),
                totalPrice: getters.getDiscountedPrice(item) * item.quantity
            }));
        },

        subtotal: state => {
            return state.cart.reduce((total, item) => total + (item.price * item.quantity), 0);
        },

        totalDiscounts: (state, getters) => {
            return state.cart.reduce((total, item) => {
                const discount = item.price - getters.getDiscountedPrice(item);
                return total + (discount * item.quantity);
            }, 0);
        },

        bonusAmount: (state, getters) => {
            const discountedTotal = getters.cartItemsWithDiscounts.reduce(
                (total, item) => total + item.totalPrice, 0
            );
            return Math.round(discountedTotal * (state.bonusPercentage / 100));
        },

        cartTotal: (state, getters) => {
            const discountedTotal = getters.cartItemsWithDiscounts.reduce(
                (total, item) => total + item.totalPrice, 0
            );
            return discountedTotal - getters.bonusAmount;
        },
        findProductByName: (state) => (name) => {
            return state.products.find(product => product.name === name);
        },
    }
})
